/* eslint-disable no-else-return */
import React from 'react';
import moment from 'moment';
import { Route, Redirect } from 'react-router-dom';
import { getCurrentUser, setCurrentUser } from './Users';
// import { isAuthGuardActive } from '../constants/defaultValues';

// getCurrentUser
const ProtectedRoute = ({
  component: Component,
  roles = undefined,
  ...rest
}) => {
  function isAuthGuardActive() {
    const user = JSON.parse(localStorage.getItem('gogo_current_user'));
    const mExpiryTime =
      user && user.expiration ? moment(new Date(user.expiration * 1000)) : null;
      
    const mCurrentTime = moment();
    if (!mExpiryTime || mCurrentTime.isAfter(mExpiryTime)) {
      setCurrentUser();
    }
    return true;
  }

  const setComponent = (props) => {
    if (isAuthGuardActive()) {
      const currentUser = getCurrentUser();
      if (currentUser) {
        return <Component {...props} />;
      } else {
        return (
          <Redirect
            to={{
              pathname: '/user/login',
              state: { from: props.location },
            }}
          />
        );
      }
    } else {
      return <Component {...props} />;
    }
  };

  return <Route {...rest} render={setComponent} />;
};

const UserRole = {
  Admin: 0,
  Editor: 1,
};

export { ProtectedRoute, UserRole };
