/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-return-await */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { baseUrl, adminRoot } from '../../constants/defaultValues';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../types';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

import { setCurrentUser } from '../../helpers/Users';
// import data from '../../constants/menu';

const headers = {
  'Content-Type': 'application/json',
  Connect: 'db',
};
const loginWithEmailPasswordAsync = async (email, password) => {
  const data = await axios.post(
    `${baseUrl}auth/login`,
    { email, password },
    {
      headers,
    }
  );
  return data;
};

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);

    if (loginUser.data.code === 1) {
      setCurrentUser(loginUser.data);
      yield put(loginUserSuccess(loginUser.data));
      history.push('/app/dashboard-page');
    } else {
      yield put(loginUserError(loginUser.data.msg));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password, username, isBrandManagerView) => {
  const data = await axios.post(
    `${baseUrl}auth/signup`,
    { username, password, email, isBrandManagerView },
    { headers }
  );
  return data;
};

function* registerWithEmailPassword({ payload }) {
  const { email, password, username, isBrandManagerView } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password,
      username,
      isBrandManagerView
    );
    if (registerUser.data.code === 1) {
      setCurrentUser(registerUser.data);
      yield put(registerUserSuccess(registerUser.data));
      history.push('/user/login');
    } else {
      yield put(registerUserError(registerUser.data.msg));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const logoutAsync = async (history) => {
  await axios
    .signOut()
    .then((user) => user)
    .catch((error) => error);
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

// const forgotPasswordAsync = async (email) => {
//   return await auth
//     .sendPasswordResetEmail(email)
//     .then((user) => user)
//     .catch((error) => error);
// };

const forgotPasswordAsync = async (email) => {
  const data = await axios.post(
    `${baseUrl}auth/forgotpassword`,
    { email },
    { headers }
  );
  return data;
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const response = yield call(forgotPasswordAsync, email);
    const responseData = response.data;

    if (responseData.code === 1) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(responseData.msg || responseData.err || 'Reset password failed'));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

// const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
//   const data = await auth.confirmPasswordReset(resetPasswordCode, newPassword);

//   return data;
// };

const resetPasswordAsync = async (emailHash, resetToken, newPassword) => {
  const data = await axios.post(
    `${baseUrl}auth/resetpassword`,
    { emailHash, resetToken, newPassword },
    { headers }
  );
  return data;
};

function* resetPassword({ payload }) {
  const { newPassword, resetToken, emailHash } = payload;
  try {
    const response = yield call(
      resetPasswordAsync,
      emailHash,
      resetToken,
      newPassword
    );
    const responseData = response.data;
    if (responseData.code === 1) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(responseData.msg || responseData.err || 'Reset password failed'));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
